import Cookies from 'js-cookie';
import { config } from 'dotenv';
config();

export function getAllCookies() {
  try {
    return Cookies.get();
  }
  catch (error) {
    throw error;
  }
}

export function setCookie(
  key: string,
  value: string,
  expireTime: number | Date
) {
  try {
    return Cookies.set(key, value, {
      domain: process.env.REACT_APP_DOMAIN,
      expires: expireTime,
    });
  }
  catch (error) {
    throw error;
  }
}

export function getCookie(key: string) {
  try {
    return Cookies.get(key);
  }
  catch (error) {
    throw error;
  }
}

export function removeCookie(key: string) {
  try {
    return Cookies.remove(key, {
      domain: process.env.REACT_APP_DOMAIN,
    });
  }
  catch (error) {
    throw error;
  }
}